import { tv } from "tailwind-variants";

const tvAvatar = tv({
  base: "h-8 w-8 lg:h-10 lg:w-10 rounded-full overflow-hidden uppercase flex items-center justify-center lg:text-lg text-base",
});

export function Avatar({
  first_name,
  last_name,
  img_id,
  className,
  showDefault = true,
}: {
  first_name: string | null;
  last_name: string | null;
  img_id?: string | null;
  className?: string;
  showDefault?: boolean;
}) {
  return img_id ? (
    <div className={tvAvatar({ className })}>
      <img
        src={`/files/${img_id}`}
        alt=""
        className="w-full h-full object-cover aspect-square"
        width="40"
        height="40"
      />
    </div>
  ) : (
    <div
      className={tvAvatar({ className: `${className} bg-sky-800 text-white` })}
    >
      {first_name ? first_name[0] : showDefault ? "R" : ""}
      {last_name ? last_name[0] : showDefault ? "E" : ""}
    </div>
  );
}
